import { decode } from './jwt';

const ACCESS_TOKEN_AUC = 'accessTokenAUC';

export const setAccessTokenLocalStorage = (token: string) => localStorage.setItem(ACCESS_TOKEN_AUC, token);

export const getAccessTokenFromLocalStorage = () => localStorage.getItem(ACCESS_TOKEN_AUC);

export const removeAccessTokenFromLocalStorage = () => localStorage.removeItem(ACCESS_TOKEN_AUC);

export const getUsernameFromToken = () => {
    const token = getAccessTokenFromLocalStorage();
    if (token) {
        const payload = decode(token);
        // @ts-expect-error temporary
        return payload['name'] ? payload['name'] : payload['unique_name'];
    }
    return 'User';
};