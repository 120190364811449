import React, { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { UserActiveCounter } from '../../../types/userActiveCounter';
import { activeCounterTableHeaders } from './activeCounterTableUtils';

export interface ActiveCounterTableProps {
    rows: UserActiveCounter[]
}

const ActiveCounterTable: FC<ActiveCounterTableProps> = ({
    rows
}) => {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {activeCounterTableHeaders.map((header) => (
                            <TableCell align="left">{header}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.clientId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.clientId}
                            </TableCell>
                            <TableCell align="left">
                                {row.clientName}
                            </TableCell>
                            <TableCell align="left">
                                {row.counter ? row.counter : 'Error'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ActiveCounterTable;