import {ClientDomain, UserActiveCounter } from '../types/userActiveCounter';
import { isEmpty } from '../utils/common';

export const UserActiveCounterConverter = {
    convertFromApiToUserActiveCounters(data: any[]): UserActiveCounter[] {
        if (isEmpty(data)) return [];

        const counters: UserActiveCounter[] = [];

        data.forEach(d => {
            const counter = UserActiveCounterConverter.convertFromApiToUserActiveCounter(d);
            counter && counters.push(counter);
        });

        return counters;
    },
    convertFromApiToUserActiveCounter(data: any): UserActiveCounter | undefined {
        if (!data) return undefined;

        return {
            clientId: data.clientId,
            clientName: data.clientName,
            counter: data.counter
        };
    },
    convertFromApiToClientDomains(data: any[]): ClientDomain[] {
        if (isEmpty(data)) return [];

        const clients: ClientDomain[] = [];

        data.forEach(d => {
            const client = UserActiveCounterConverter.convertFromApiToClientDomain(d);
            client && clients.push(client);
        });

        return clients;
    },
    convertFromApiToClientDomain(data: any): ClientDomain | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            name: data.name,
            domain: data.domain
        };
    }
};