import { useState } from 'react';
import useDashboardController from './useDashboardController';
import ActiveCounterTable from './activeCounterTable/ActiveCounterTable';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import i18n from '../../translations/i18n';
import CustomButton from '../../components/button/CustomButton';
import styles from './dashboard.module.scss';
import { formatDate } from '../../utils/date';
import { CustomDatePicker } from '../../components/datePicker/CustomDatePicker';
import { DashboardProvider } from '../../context/DashboardProvider';
import { ClientModal } from '../../components/clientModal/ClientModal';
import ExportSelector from './ExportSelector';

const Dashboard = () => {
    const [showClientModal, setShowClientModal] = useState<boolean>(false);

    const {
        counters, getUserClientCounters, loading, fromDate, setFromDate, toDate, setToDate
    } = useDashboardController();

    const saveFromDate = (val: Date) => val && setFromDate(val);
    const saveToDate = (val: Date) => val && setToDate(val);

    const handleGetUserClientCounters = () => getUserClientCounters(formatDate(fromDate), formatDate(toDate));

    return (
        <DashboardProvider handleGetUserClientCounters={handleGetUserClientCounters}>
            <div style={{height: 800, margin: '16px'}}>
                <div className={styles.headerWrapper}>
                    <div className={styles.searchWrapper}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CustomDatePicker
                                label={i18n.t('common.from')}
                                value={fromDate}
                                onChange={saveFromDate}
                            />
                            <CustomDatePicker
                                label={i18n.t('common.to')}
                                value={toDate}
                                onChange={saveToDate}
                            />
                        </LocalizationProvider>
                        <CustomButton
                            onClick={handleGetUserClientCounters}
                            text={i18n.t('common.search')}
                            sx={styles.btn}
                            loading={loading}
                        />
                    </div>
                    <div className={styles.clientBtnWrapper}>
                        <CustomButton
                            text={i18n.t('common.updateClients')}
                            onClick={() => setShowClientModal(true)}
                            sx={styles.btn}
                        />
                        <ExportSelector data={counters}/>
                    </div>
                </div>
                <ActiveCounterTable
                    rows={counters}
                />
            </div>
            {showClientModal && <ClientModal onClose={() => setShowClientModal(false)}/>}
        </DashboardProvider>
    );
};


export default Dashboard;