import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/Dashboard';


const AzureAppRoutes = () => {

    return (
        <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='*' element={<Navigate to='/' />} />
            <Route path='/login' element={<Login />} />
        </Routes>
    );
};

export default AzureAppRoutes;
