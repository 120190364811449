import { FC, useState } from 'react';
import {ClientDomain, ClientDomainUpdate} from '../../types/userActiveCounter';
import CustomButton from '../../components/button/CustomButton';
import { CustomInput } from '../../components/input/CustomInput';
import i18n from '../../translations/i18n';
import { isBlank } from '../../utils/common';
import styles from './client-modal.module.scss';
import { createClientDomainApi, deleteClientDomainApi, updateClientDomainApi } from '../../api/userActiveCounter';
import { CustomModal } from '../modal/CustomModal';
import { useDashboardContext } from '../../context/DashboardProvider';

interface ClientEntryProps {
    clientDomain: ClientDomain;
    onCancel?: () => void;
    onClose?: () => void;
}

interface ModalData {
    title: string;
    text: string;
    onOk: () => void;
    onOkText?: string;
}

export const ClientEntry: FC<ClientEntryProps> = ({
    clientDomain, onCancel, onClose
}) => {
    const [name, setName] = useState<string>(clientDomain.name);
    const [domain, setDomain] = useState<string>(clientDomain.domain);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ModalData | undefined>(undefined);

    const { handleGetUserClientCounters } = useDashboardContext();

    const isSaveDisabled = isBlank(name) || isBlank(domain);
    const isNewClient = clientDomain.id === 0;

    const onNameInputChange = (value: string) => setName(value);
    const onDomainInputChange = (value: string) => setDomain(value);

    const onSaveOrder = () => {
        const clientDomainUpdate: ClientDomainUpdate = { name: name, domain: domain };

        setLoading(true);
        (isNewClient
            ? createClientDomainApi(clientDomainUpdate)
            : updateClientDomainApi(clientDomain.id, clientDomainUpdate))
            .then(() => {
                handleGetUserClientCounters();
                onClose && onClose();
            })
            .finally(() => setLoading(false));
    };

    const onDeleteClient = () => {
        setLoading(true);
        deleteClientDomainApi(clientDomain.id)
            .then(() => {
                handleGetUserClientCounters();
                onClose && onClose();
            })
            .finally(() => setLoading(false));
    };

    const onCancelAction = () => {
        setName(clientDomain.name);
        setDomain(clientDomain.domain);
        onCancel && onCancel();
    };
    
    const handleSaveModal = () => {
        setModalData({
            title: i18n.t('modal.saveClientTitle'),
            text: i18n.t('modal.saveClient', {clientName: name}),
            onOk: onSaveOrder
        });
    };

    const handleDeleteModal = () => {
        setModalData({
            title: i18n.t('modal.deleteClientTitle'),
            text: i18n.t('modal.deleteClient', {clientName: name}),
            onOk: onDeleteClient,
            onOkText: i18n.t('common.delete')
        });
    };
    
    return (
        <div id='card-section' key={clientDomain.id} className={styles.cardSections}>
            <div className={styles.section}>
                <CustomInput
                    label={i18n.t('common.name')}
                    value={name}
                    onChange={onNameInputChange}
                />
                <CustomInput
                    label={i18n.t('common.domain')}
                    value={domain}
                    onChange={onDomainInputChange}
                />
            </div>
            <div className={styles.btnClientEntry}>
                <CustomButton
                    onClick={handleSaveModal}
                    text={i18n.t('common.save')}
                    disabled={isSaveDisabled}
                    sx={styles.btn}
                    loading={loading}
                />
                <CustomButton
                    onClick={onCancelAction}
                    text={i18n.t('common.cancel')}
                    sx={styles.btn}
                />
                <CustomButton
                    onClick={handleDeleteModal}
                    text={i18n.t('common.delete')}
                    sx={styles.btn}
                />
            </div>
            <CustomModal
                isOpen={modalData != undefined}
                title={modalData?.title ?? ''}
                text={modalData?.text ?? ''}
                onOk={modalData?.onOk ?? (() => {})}
                onOkText={modalData?.onOkText}
                onCancel={() => setModalData(undefined)}
            />
        </div>
    );

};