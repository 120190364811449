import React, {FC} from 'react';
import styles from './custom-modal.module.scss';
import ModalContainer from '../../components/modalContainer/ModalContainer';
import CustomButton from '../../components/button/CustomButton';
import i18n from '../../translations/i18n';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { Divider } from '@mui/material';

interface CustomModalProps {
    isOpen: boolean;
    title: string;
    text: string;
    onOk: () => void;
    onCancel: () => void;
    onOkText?: string;
    onCancelText?: string;
}

export const CustomModal: FC<CustomModalProps> = ({
    isOpen, title, text, onOk, onCancel, onOkText, onCancelText,
}) => {

    const btnOkText = onOkText ? onOkText : i18n.t('common.save');
    const btnCancelText = onCancelText ? onCancelText : i18n.t('common.cancel');
    
    if (!isOpen) return;

    return (
        <ModalContainer onClose={onCancel}>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {title}
                </div>
                <div className={styles.icon} onClick={onCancel}>
                    <CloseIcon stroke='#808080'/>
                </div>
            </div>
            <Divider/>
            <div className={styles.txt}>
                {text}
            </div>
            <div className={styles.btnClientEntry}>
                <CustomButton
                    onClick={onOk}
                    text={btnOkText}
                    sx={styles.btn}
                />
                <CustomButton
                    onClick={onCancel}
                    text={btnCancelText}
                    sx={styles.btn}
                />
            </div>
        </ModalContainer>
    );

};