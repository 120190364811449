import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import i18n from '../../translations/i18n';
import { UserActiveCounter } from '../../types/userActiveCounter';
import { activeCounterTableHeaders } from './activeCounterTable/activeCounterTableUtils';

interface CustomSelectorProps {
    data: UserActiveCounter[];
}

const ExportSelector: React.FC<CustomSelectorProps> = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const exportToCSV = () => {
        const csvRows = [];
        csvRows.push(activeCounterTableHeaders.join(','));

        data.forEach(item => {
            const values = [item.clientId, item.clientName, item.counter];
            csvRows.push(values.join(','));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        saveAs(blob, 'user-active-counters.csv');
        handleCloseMenu();
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'user-active-counters.xlsx');
        handleCloseMenu();
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleOpenMenu}
                style={{
                    height: '48px', backgroundColor: '#242222', fontFamily: 'Lato', fontSize: '16px', textTransform: 'none'
                }}
            >
                {i18n.t('common.export')}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={exportToCSV}>
                    {i18n.t('common.csv')}
                </MenuItem>
                <MenuItem onClick={exportToExcel}>
                    {i18n.t('common.excel')}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default ExportSelector;
