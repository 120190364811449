import { getMsalAuthClientId, getMsalAuthRedirectUrl, getMsalAuthTenantId } from '../utils/loginProvider';
//import {LogLevel} from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: getMsalAuthClientId() || '',
        authority: `https://login.microsoftonline.com/${getMsalAuthTenantId()}`,
        redirectUri: getMsalAuthRedirectUrl() || '',
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },

    /*           Uncomment if you need to see msal logs           */
    // system: {
    //   loggerOptions: {
    //     loggerCallback: (level, message, containsPii) => {
    //
    //       if (containsPii) {
    //         return;
    //       }
    //       switch (level) {
    //         case LogLevel.Error:
    //           console.error(message);
    //           return;
    //         case LogLevel.Info:
    //           console.info(message);
    //           return;
    //         case LogLevel.Verbose:
    //           console.debug(message);
    //           return;
    //         case LogLevel.Warning:
    //           console.warn(message);
    //           return;
    //         default:
    //           return;
    //       }
    //     }
    //   }
    // }
};

export const loginRequest = {
    scopes: ['api://active-user-counter/manage']
};
