import React, { FC } from 'react';
import styles from './input.module.scss';

export const TEXT = 'text';
export const FILE = 'file';
export const EMAIL = 'email';

export const PASSWORD = 'password';

export type InputType = typeof TEXT | typeof FILE | typeof EMAIL | typeof PASSWORD;

interface CustomInputProps {
    id?: string;
    value?: string;
    onChange: (value: string) => void;
    type?: InputType;
    placeholder?: string;
    className?: string;
    label?: string;
    disabled?: boolean;
}

export const CustomInput: FC<CustomInputProps> = ({
    id, value, label, type, placeholder, onChange, className, disabled
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        onChange(value);
    };

    const containerClassName = `${styles.container} ${className}`;
    return (
        <div className={containerClassName}>
            <label className={styles.label}>
                {label}
            </label>
            <input
                id={id}
                value={value}
                className={styles.input}
                onChange={handleChange}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    );
};