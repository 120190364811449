import { FC, ReactNode, createContext, useContext } from 'react';

interface DashboardContextType {
    handleGetUserClientCounters: () => void;
}

interface DashboardProviderProps {
    children: ReactNode;
    handleGetUserClientCounters: () => void;
}

const DashboardContext = createContext<DashboardContextType>(null!);

export const DashboardProvider: FC<DashboardProviderProps> = ({ children , handleGetUserClientCounters }) => {
    return (
        <DashboardContext.Provider value={{ handleGetUserClientCounters }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboardContext = () => {
    return useContext(DashboardContext);
};
