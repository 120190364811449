import React, {FC, useEffect, useState} from 'react';
import { ToastContainer } from 'react-toastify';
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import AppRoutes from './AppRoutes';
import Login from './pages/login/login';
import {setAccessTokenLocalStorage} from './utils/token';
import {loginRequest} from './config/auth-config';
import {CircularProgress} from '@mui/joy';
import AppHeader from './components/appHeader/AppHeader';


const App: FC = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!instance) return;
        const silentRequest = { ...loginRequest, account: instance.getAllAccounts()[0] };
        const acquireTokenSilentAndSetAccessToken = async () => {
            await instance.initialize();
            instance
                .acquireTokenSilent(silentRequest)
                .then((response) => {
                    setAccessTokenLocalStorage(response.accessToken);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Silent token acquisition failed:', error);
                    setTimeout(() => {
                        instance
                            .acquireTokenSilent(silentRequest)
                            .then((response) => {
                                setAccessTokenLocalStorage(response.accessToken);
                                setLoading(false);
                            })
                            .catch(error => {
                                console.error('Failed to acquire token on retry:', error);
                                instance.loginRedirect(loginRequest);
                            });
                    }, 500);
                });
        };

        if (inProgress === 'none' && accounts.length > 0) {
            acquireTokenSilentAndSetAccessToken();
        }
    }, [instance, accounts, inProgress]);

    return (
        <>
            <AuthenticatedTemplate>
                {loading
                    ? <div className='loading'>
                        <CircularProgress size='lg'/>
                    </div>
                    : <>
                        <AppHeader />
                        <ToastContainer autoClose={5000} theme={'dark'} position={'bottom-right'} />
                        <AppRoutes />
                    </>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
