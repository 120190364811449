import {useCallback, useEffect, useState} from 'react';
import { getUserClientCountersApi} from '../../api/userActiveCounter';
import { UserActiveCounterConverter } from '../../converters/UserActiveCounterConverter';
import {UserActiveCounter } from '../../types/userActiveCounter';
import { formatDate } from '../../utils/date';


const useDashboardController = () => {
    const today = new Date();
    const [counters, setCounters] = useState<UserActiveCounter[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fromDate, setFromDate] = useState<Date>(new Date(today.getFullYear(), today.getMonth(), 1));
    const [toDate, setToDate] = useState<Date>(today);

    const getUserClientCounters = useCallback((from: string, to: string) => {
        setLoading(true);
        getUserClientCountersApi(from, to)
            .then(res =>  {
                if (res.error) return;
                setCounters(UserActiveCounterConverter.convertFromApiToUserActiveCounters(res.data));
            })
            .finally(() => setLoading(false));
    }, []);
    

    useEffect(() => {
        if (!loading) {
            getUserClientCounters(formatDate(fromDate), formatDate(toDate));
        }
    }, []);

    return {
        counters,
        loading,
        getUserClientCounters,
        fromDate,
        setFromDate,
        toDate,
        setToDate
    };
};

export default useDashboardController;