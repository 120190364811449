import React, {FC, useState} from 'react';
import styles from './client-modal.module.scss';
import ModalContainer from '../../components/modalContainer/ModalContainer';
import CustomButton from '../../components/button/CustomButton';
import useClientModalController from './useClientModalController';
import { ClientEntry } from './ClientEntry';
import i18n from '../../translations/i18n';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import {CircularProgress, Divider } from '@mui/material';

interface ClientModalProps {
    onClose: () => void;
}

export const ClientModal: FC<ClientModalProps> = ({
    onClose
}) => {
    const {clientDomains, loading} = useClientModalController();
    const [showNewSection, setShowNewSection] = useState<boolean>(false);
    
    return (
        <ModalContainer onClose={onClose}>
            <div className={styles.header}>
                <div className={styles.headerTxt}>
                    {i18n.t('common.clientDomains')}
                </div>
                <div className={styles.icon} onClick={onClose}>
                    <CloseIcon stroke='#808080'/>
                </div>
            </div>
            <Divider/>
            {loading
                ? <CircularProgress style={{alignSelf: 'center'}}/>
                : <>
                    {clientDomains.map((client, index) => (
                        <ClientEntry key={index} clientDomain={client} onClose={onClose} />
                    ))}
                    {showNewSection &&
                        <ClientEntry
                            clientDomain={{id: 0, name: '', domain: ''}}
                            onCancel={() => setShowNewSection(false)}
                            onClose={onClose}
                        />
                    }
                    <CustomButton
                        onClick={() => setShowNewSection(true)}
                        text={'+'}
                        sx={styles.addBtn}
                    />
                </>
            }
        </ModalContainer>
    );

};