import { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers';


interface CustomDatePickerProps {
    label: string;
    value: Date;
    onChange: (date: Date) => void;
}

export const CustomDatePicker: FC<CustomDatePickerProps> = ({
    label, value, onChange
}) => {

    const handleOnChange = (val: Date | null) => val && onChange(val);

    return (
        <DatePicker
            label={label}
            value={value}
            onChange={handleOnChange}
            slotProps={{
                textField: {
                    sx: {
                        height: '48px',
                        '& .MuiInputBase-root': {
                            height: '100%'
                        },
                        '& .MuiInputBase-input': {
                            fontSize: '14px',
                            height: '100%'
                        },
                        '& .MuiInputLabel-root': {
                            fontSize: '14px',
                            height: '100%'
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: '18px',
                        }
                    }
                }
            }}
        />
    );
};