import React, { FC, useMemo, useState } from 'react';
import styles from './app-header.module.scss';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import i18n from '../../translations/i18n';
import { useMsal } from '@azure/msal-react';
import { getUsernameFromToken } from '../../utils/token';


const AppHeader: FC = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const { instance } = useMsal();

    const username = useMemo(() => getUsernameFromToken(), []);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl((prev) => (prev ? null : event.currentTarget));
    };

    const handleClose = () => setAnchorEl(null);

    const azureLogout = () => {
        const logoutRequest = { postLogoutRedirectUri: '/', mainWindowRedirectUri: '/' };
        localStorage.removeItem('accessTokenAUC');
        instance.logoutRedirect(logoutRequest);
    };

    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const TypographyStyle = {
        p: 1.5,
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
    };

    return (
        <div className={styles.header}>
            <div className={styles.userInfo}>
                <div className={styles.userInfo} aria-haspopup="true" aria-controls={id} onClick={handleClick} role="button" >
                    <div className={styles.avatar}>
                        <AvatarIcon/>
                    </div>
                    <div className={styles.username}>
                        {username}
                    </div>
                    <div className={styles.arrow}>
                        <ArrowIcon />
                    </div>
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={styles.logoutButton} onClick={azureLogout}>
                        <Typography className={`${styles.text} ${styles.settingWrapper}`} sx={TypographyStyle}>
                            <LogoutIcon />
                            {i18n.t('common.signOut')}
                        </Typography>
                    </div>
                </Popover>
            </div>
        </div>
    );
};

export default AppHeader;