import axiosInstance from './axiosInstance';
import {getAccessTokenFromLocalStorage} from '../utils/token';


export const configDefault = async () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin',
        }
    };
};

export const configDefaultWithToken = async () => {
    const accessToken = getAccessTokenFromLocalStorage();

    return {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin'
        }
    };
};

export const getApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.get(url, config));
};

export const postApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.post(url, data, config));
};

export const putApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.put(url, data, config));
};

export const patchApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.patch(url, data, config));
};

export const deleteApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.delete(url, config));
};

export const getApiResponse = async (request: Promise<any>) => {
    const response: { data: any; error: any; errorMsg: any, status: number } = {
        data: {},
        error: null,
        errorMsg: null,
        status: 0,
    };

    await request
        .then((res) => {
            response.data = res.data;
            response.status = res.status;
        })
        .catch((error) => {
            response.error = error;
            response.errorMsg = error.response?.data?.error;
            response.status = error.response?.status;
        });

    return response;
};
