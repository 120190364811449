export const ENGLISH = {
    app: {
        name: 'Organitz Active User Counter'
    },
    common: {
        cancel: 'Cancel',
        clientDomains: 'Client domains',
        csv: 'csv',
        delete: 'Delete',
        domain: 'Domain',
        excel: 'excel',
        export: 'Export',
        from: 'From',
        name: 'Name',
        save: 'Save',
        search: 'Search',
        signedInAs: 'Signed in as',
        singIn: 'Sign in',
        signOut: 'Sign out',
        singInWithMicrosoft: 'Sign in with Microsoft',
        to: 'To',
        updateClients: 'Update clients',
    },
    modal: {
        saveClientTitle: 'Add new client',
        deleteClientTitle: 'Remove client',
        saveClient: 'Are you sure you want add a new client {{clientName}}?',
        deleteClient: 'Are you sure you want to delete client {{clientName}}?'
    }

};