import {ClientDomainUpdate} from '../types/userActiveCounter';
import { configDefaultWithToken, deleteApiCall, getApiCall, patchApiCall, postApiCall } from './base';

const userActiveCounterUrl = '/api/user-active';
const userClientUrl = `${userActiveCounterUrl}/client`;

export const getUserClientCountersApi = async (from: string, to: string) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            from: from,
            to: to
        }
    };

    return await getApiCall(userActiveCounterUrl, config);
};

export const getClientDomainApi = async () => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(userClientUrl, config);
};

export const createClientDomainApi = async (data: ClientDomainUpdate) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await postApiCall(userClientUrl, data, config);
};

export const updateClientDomainApi = async (clientId: number, data: ClientDomainUpdate) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await patchApiCall(`${userClientUrl}/${clientId}`, data, config);
};

export const deleteClientDomainApi = async (clientId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await deleteApiCall(`${userClientUrl}/${clientId}`, config);
};

