import React, {FC} from 'react';
import styles from './custom-button.module.scss';
import CircularProgress from '@mui/joy/CircularProgress';

interface CustomButtonProps {
    text: string,
    onClick: () => void,
    loading?: boolean,
    sx?: any,
    disabled?: boolean
}

const CustomButton: FC<CustomButtonProps> = ({
    text, onClick, loading, sx, disabled
}) => {

    if (loading) {
        return (
            <div className={`${sx} ${styles.container}`}>
                <div className={styles.btn}>
                    <CircularProgress color="neutral" size="sm" variant="soft"/>
                </div>
            </div>
        );
    }

    return (
        <div
            className={`${sx} ${styles.container} ${disabled && styles.disabled}`}
            onClick={disabled ? undefined : onClick}
        >
            <div className={styles.btn}>
                {text}
            </div>
        </div>
    );
};

export default CustomButton;