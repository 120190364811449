import {useCallback, useEffect, useState} from 'react';
import { ClientDomain } from '../../types/userActiveCounter';
import { getClientDomainApi } from '../../api/userActiveCounter';
import { UserActiveCounterConverter } from '../../converters/UserActiveCounterConverter';


const useClientModalController = () => {
    const [clientDomains, setClientDomains] = useState<ClientDomain[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getClientDomain = useCallback(() => {
        setLoading(true);
        getClientDomainApi()
            .then(res =>  {
                if (res.error) return;
                setClientDomains(UserActiveCounterConverter.convertFromApiToClientDomains(res.data));
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (!loading) {
            getClientDomain();
        }
    }, []);

    return {
        clientDomains,
        loading
    };
};

export default useClientModalController;