import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {BrowserRouter} from 'react-router-dom';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {msalConfig} from './config/auth-config';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <MsalProvider instance={new PublicClientApplication(msalConfig)}>
        {/*<Provider store={store}>*/}
        <BrowserRouter>
            <App />
        </BrowserRouter>
        {/*</Provider>*/}
    </MsalProvider>
);
