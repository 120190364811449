import React from 'react';
import {useMsal} from '@azure/msal-react';
import './login.scss';
import {loginRequest} from '../../config/auth-config';
import {Button} from '@mui/material';
import i18n from '../../translations/i18n';

const Login = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    return (
        <div className='root'>
            <div className='label'>
                {i18n.t('common.singIn')} {i18n.t('app.name')}
            </div>
            <Button className='login' variant={'contained'} onClick={handleLogin}>
                {i18n.t('common.singInWithMicrosoft')}
            </Button>
        </div>
    );
};

export default Login;
